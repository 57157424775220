import { useState } from "react";
import resto from "../MockData/data";
import Resto from "./Resto";



const Middle = () => {
  return (
    <div className="middle">
      <Resto/>
    </div>
  );
};

export default Middle;
